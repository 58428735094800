.project-info{
    /* border: 5px solid black; */
    height: 80%;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content:space-evenly;
    align-items:center;
}

img{
    /* border: 4px solid pink; */
    height: 15vw;
    margin-bottom: 250px;

}
.carousel{
    /* border: 4px solid black; */
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content:space-evenly;
    align-items:center;
}
.carousel-caption{
    /* border: 4px solid gray; */
    /* font-size: 100px; */
    /* margin-top: 500px; */
    
}
@media screen and (max-width: 600px) {
    .project-info{
        width: 95%;
    }
    img{
        height: 25vw;
    }

  }
  @media screen and (max-width: 400px) {
    img{
        height: 40vw;
    }
    .carousel-caption{
        width: 80%;
        left: 10%;
    }
    h1{
        font-size: 30px;
    }
    h4{
        font-size: 16px;
    }
  }