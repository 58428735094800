* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  
}

/* html {
  background: linear-gradient(-45deg, #d6c3bd, #c392a5, #7fbbd1, #83d6c3);
  background-size: 400% 400%;
  animation: gradient 30s ease infinite;
} */
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.App {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(-45deg, #d6c3bd, #c392a5, #7fbbd1, #83d6c3);
  background-size: 400% 400%;
  animation: gradient 30s ease infinite;
}

@media screen and (max-width: 1000px) {
  .App{
    width: 100vw;
  }
}
@media screen and (max-width: 500px) {
  .App{
    width: 100vw;
  }
}
