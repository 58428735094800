main {
  background-color: rgba(211, 211, 211, 0.438);
  border: 10px solid rgba(14, 82, 18, 0.527);
  font-size: 20px;
  border-radius: 100px;
  height: 80vh;
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* position: absolute; */
}
@media screen and (max-width: 1000px) {
  main {
    margin: 0 auto;
    width: 80%;
  }
}
@media screen and (max-width: 500px) {
  main {
    border-radius: 0px;
    height: 100%;
    width: 100%;
    font-size: smaller;
  }
}