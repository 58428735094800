nav {
    position: relative;
    top: 10px;
    border-bottom: 3px solid rgba(131, 113, 113, 0.671);
    border-radius: 15px;
    font-size: 16px;
    height: 2.2em;
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    opacity: 0.9;
    background-color: rgba(33, 85, 27, 0.315);
    color: rgba(7, 7, 7, 0.849);
    font-weight: 500;
  }
  ul{
    /* border: 1px solid black; */
    margin-top: 1px;
    width: 99%;
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items:center;
  }
  nav li {
    /* display: inline; */
    /* border: 2px solid rgba(165, 147, 147, 0.589); */
    /* border-bottom: none; */
    background-color: rgba(211, 211, 211, 0.438);
    border-radius: 15px;
    padding-right: 10px;
    padding-left: 10px;
  }
  nav li:hover {
    border-radius: 0px;
    cursor: pointer;
    animation: fade 1s;
  }
  @keyframes fade {
    from {
      border-radius: 15px;
    }
    to {
      border-radius: 0px;
    }
  }

  @media screen and (max-width: 650px) {
    nav{
      width: 80%;
    }
  }
  @media screen and (max-width: 400px) {
    nav{
      width: 90%;
      font-size: 14px;
    }
  }